@import './fonts.scss';
@import './reset.scss';
@import 'node_modules/aos/src/sass/aos.scss';

html {
	scroll-behavior: smooth;
	background: #F8F8F8;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
	-webkit-text-size-adjust:100%;
	-ms-text-size-adjust:100%;
	zoom:100%;
}

body {
	font-family: 'Inter-Regular', sans-serif;
	font-size: 100%;
	font-size: 16px;
	color: #000000;
}

img {
	max-width: 100%;
	height: auto;
}

a {
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

main {
	background: none;
	padding-top: 20px;

	@media (max-width:575px) {
		padding-top: 14px;
	}
}

header {
	background: none
}

.container {
	width: 100%;
	max-width: 1024px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 20px;

	@media (max-width: 575px) {
		padding: 0 14px;
	}
}

header {
	top: 20px;
	position: sticky;
	z-index: 100;

	@media (max-width: 575px) {
		top: 14px;
	}

	.container {
		.inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: relative;

			.left {
				background: #FFFFFF;
				box-shadow: 2px 2px 20px 2px rgba(121, 121, 121, 0.1);
				border-radius: 10px;
				padding: 5px 32px 5px 5px;
				gap: 29px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				position: relative;
				z-index: 1;

				#logo {
					width: 40px;
					min-width: 40px;
					aspect-ratio: 1;
					position: relative;
					z-index: 0;

					img {
						width: 100%;
					}
				}

				ul {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 29px;

					@media (max-width: 575px) {
						display: none;
					}

					li {
						a {
							font-family: 'Inter-Medium', sans-serif;
							color: #000;
							font-size: 14px;

							&:hover {
								color: #1313FF;
							}
						}
					}
				}

				.burger {
					width: 40px;
					height: 40px;
					display: none;
					align-items: center;
					justify-content: center;
					gap: 6px;
					flex-direction: column;
					background: none;
					border: 0;
					padding: 0;

					span {
						background: #000;
						width: 22px;
						height: 2px;
						border-radius: 2px;
						display: block;
					}

					@media (max-width: 575px) {
						display: flex;
					}
				}

				&:hover {
					#logo {
						img {
							transform: scaleX(-1);
						}
					}
				}

				@media (max-width: 575px) {
					padding: 5px;
					gap: 5px;

					&.show {
						animation: width-anim-show 0.3s ease-in-out forwards;

						ul {
							&.active {
								display: flex;
							}
						}

						.burger {
							gap: 0;

							span {
								&:nth-child(1) {
									transform: rotate(45deg);
								}

								&:nth-child(2) {
									display: none;
								}

								&:nth-child(3) {
									transform: rotate(-45deg);
									margin-top: -2px;
								}
							}
						}
					}

					@keyframes width-anim-show {
						0% {
							min-width: 94px;
						}
						100% {
							min-width: 100%;
						}
					}

					&.hide {
						animation: width-anim-hide 0.3s ease-in-out forwards;
					}

					@keyframes width-anim-hide {
						0% {
							min-width: 100%;
						}
						100% {
							min-width: 94px;
						}
					}
				}
			}

			.right {
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
}

section.first {
	margin-top: 53px;

	.container {
		padding: 0 0 0 20px;

		@media (max-width: 575px) {
			padding: 0 0 0 14px;
		}

		.inner {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			height: 394px;

			@media (max-width: 575px) {
				height: 255px;
			}

			.text {
				font-family: 'Inter-Bold', sans-serif;
				color: #000;
				font-size: 50px;
				width: 100%;
				line-height: 121%;
				max-width: 472px;
				min-width: 322px;
				margin-left: 30px;
				margin-top: 102px;

				@media (max-width: 767px) {
					font-size: 30px;
					max-width: 220px;
					min-width: 220px;
					margin-left: 0;
					margin-top: 13px;
				}

				@media (min-width: 768px) and (max-width: 1024px) {
					margin-left: 0;
					margin-top: 68px;
					font-size: 44px;
					max-width: 316px;
					min-width: 316px;
				}

				span {
					color: #1313FF;
				}
			}

			.quotes {
				position: relative;
				overflow-x: clip;
				width: 100%;
				max-width: 502px;
				height: 100%;

				@media (max-width: 575px) {
					margin-left: -92px;
					margin-top: 33px;
				}

				.qoute {
					position: absolute;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding: 14px 20px 24px;
					gap: 8px;
					position: absolute;
					background: #FFFFFF;
					box-shadow: 2px 2px 20px 2px rgba(121, 121, 121, 0.1);
					border-radius: 20px;
					transition: all 0.3s ease-in-out;

					&:hover {
						background: #1313FF;
						color: #fff;
					}

					.type {
						font-size: 10px;
						font-family: "Inter-Regular", sans-serif;
						white-space: nowrap;
					}

					.head {
						font-size: 22px;
						font-family: "Inter-SemiBold", sans-serif;
						white-space: nowrap;
					}

					.tag {
						font-size: 14px;
						font-family: "Inter-Regular", sans-serif;
						white-space: nowrap;
					}

					&.one {
						top: 11px;
						left: 265px;
						transform: rotate(-6.2deg);

						@media (max-width:575px) {
							top: -29px;
							left: 125px;
						}

						@media (min-width: 576px) {
							animation: action1 5s infinite alternate;

							@keyframes action1 {
								0% {
									transform: translateY(0) translateX(10px) rotate(-6.2deg);
								}
								100% {
									transform: translateY(-5px) translateX(-5px) rotate(-6.2deg);
								}
							}
						}
					}

					&.two {
						top: 110px;
						left: 37px;
						transform: rotate(4.8deg);

						@media (min-width: 576px) {
							animation: action2 4s infinite alternate;

							@keyframes action2 {
								0% {
									transform: translateY(0) translateX(0) rotate(4.8deg);
								}
								100% {
									transform: translateY(-10px) translateX(-10px) rotate(9.8deg);
								}
							}
						}
					}

					&.three {
						top: 140px;
						left: 341px;
						transform: rotate(-26.2deg);

						@media (max-width: 575px) {
							display: none;
						}

						@media (min-width: 576px) {
							animation: action3 5s infinite alternate;

							@keyframes action3 {
								0% {
									transform: translateY(0px) translateX(0px) rotate(-26.2deg);
								}
								100% {
									transform: translateY(30px) translateX(-5px) rotate(-26.2deg);
								}
							}
						}
					}

					&.four {
						top: 271px;
						left: 167px;
						transform: rotate(16.8deg);

						@media (max-width: 575px) {
							display: none;
						}

						@media (min-width: 576px) {
							animation: action4 4s infinite alternate;

							@keyframes action4 {
								0% {
									transform: translateY(0) translateX(0) rotate(16.8deg);
								}
								100% {
									transform: translateY(10px) translateX(-10px) rotate(16.8deg);
								}
							}
						}
					}
				}
			}
		}
	}
}

section.second {
	margin-top: 117px;

	@media (max-width: 575px) {
		margin-top: 53px;
	}

	.container {
		.inner {
			gap: 10px;
			display: flex;
			justify-content: space-between;

			@media (max-width: 575px) {
				flex-direction: column;
				gap: 17px;
			}

			.left {
				min-width: 310px;
				background: #FFFFFF;
				border-radius: 60px;
				padding: 61px 30px 37px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				@media (max-width: 575px) {
					border-radius: 40px;
					min-width:unset;
					width:100%;
					gap: 26px;
					padding: 36px 30px 33px;
					z-index: 2;
				}

				.head {
					color: #1313FF;
					line-height: 121%;
					font-size: 50px;
					font-family: "Inter-Bold", sans-serif;

					@media (max-width: 575px) {
						font-size: 26px;
					}
				}

				a {
					background: #1313FF;
					width: 100%;
					height: 60px;
					border-radius: 40px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-family: "Inter-Medium", sans-serif;
					color: #fff;
					font-size: 18px;
					padding: 0 20px;

					@media (max-width: 575px) {
						height: 50px;
						font-size: 16px;
					}

					svg {
						transition: all 0.3s ease-in-out;
					}

					&:hover {
						svg {
							transform: translateX(-5px);
						}
					}
				}
			}

			.right {
				position: relative;
				width: 100%;
				background: #1313FF;
				border-radius: 60px;
				height: 568px;
				padding: 0 65px 63px;
				display: flex;
				align-items: flex-end;
				justify-content: flex-start;

				@media (max-width: 575px) {
					border-radius: 40px;
					z-index: 2;
					height: 578px;
					padding: 0 30px 48px;
				}

				@media (min-width: 768px) and (max-width: 1024px) {
					padding: 0 45px 63px;
				}

				.image {
					position: absolute;
					top: -142px;
					right: 35px;
					max-width: 376px;

					@media (max-width: 575px) {
						top: -31px;
						right: 26px;
						width: 282px;
					}

					@media (min-width: 768px) and (max-width: 1024px) {
						right: 2px;
					}
				}

				.text {
					display: flex;
					align-items: flex-start;
					justify-content: flex-end;
					flex-direction: column;
					gap: 35px;
					font-family: "Inter-Medium", sans-serif;
					color: #fff;
					width: 100%;
					max-width: 316px;

					&__top {
						font-size: 18px;
						line-height: 130%;

						@media (max-width: 575px) {
							font-size: 16px;
							max-width: 260px;
						}
					}

					&__bottom {
						font-size: 13px;
						line-height: 130%;

						@media (max-width: 575px) {
							font-size: 12px;
							line-height: 137%;
						}
					}
				}
			}
		}
	}
}

section.three {
	padding-top: 80px;
	padding-bottom: 49px;

	@media (max-width: 575px) {
		padding-top: 71px;
	}

	.container {
    padding: 20px 0 0 0px;
		overflow: hidden;

		@media (max-width: 575px) {
			padding: 0;
		}

		.inner {
			display: flex;
			flex-direction: column;
			position: relative;

			.heading {
				color: #1313FF;
				line-height: 121%;
				font-size: 50px;
				font-family: "Inter-Bold", sans-serif;
				margin-left: 48px;

				@media (max-width: 575px) {
					font-size: 34px;
					margin-left: 20px;
				}
			}

			.items {
				position: relative;
				display: flex;
				justify-content: flex-start;
				padding-top: 83px;
				height: 630px;
				margin-top: -80px;
				overflow: hidden;
				padding-left: 20px;

				@media (max-width: 575px) {
					padding-top: 0px;
					margin-top: 19px;
					width: 100%;
					max-width: 100%;
					height: 1083px;
					margin-left: auto;
					margin-right: auto;
				}

				@media (min-width:769px) and (max-width:1024px) {
					overflow-x: auto;
					-ms-scroll-snap-type: x mandatory;
					scroll-snap-type: x mandatory;
				
					&::-webkit-scrollbar {
						display: none;
					}
				}

				.item {
					width: 310px;
					height: 410px;
					border-radius: 60px;
					padding: 28px 37px 37px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					position: absolute;
					flex-shrink: 0;

					@media (max-width: 575px) {
						width: fit-content;
						height: 262px;
					}

					.toy {
						display: block;
						position: absolute;
						right: 18px;
    				top: -88px;
						animation: rotate4 3s infinite alternate;
						width: 140px;
						z-index: 4;
		
						@keyframes rotate4 {
							0% {
								transform: rotate(-5deg);
							}
							100% {
								transform: rotate(10deg);
							}
						}
		
					}

					.number {
						line-height: 1;
						font-size: 100px;
						font-family: "Inter-Bold", sans-serif;
						color: #1413FF;

						@media (max-width: 575px) {
							font-size: 80px;
						}
					}

					.caption {
						display: flex;
						flex-direction: column;
						gap: 21px;

						@media (max-width: 575px) {
							gap: 14px;
						}

						.name {
							color: #1313FF;
							line-height: 121%;
							font-size: 22px;
							font-family: "Inter-Bold", sans-serif;
							width: 100%;
							max-width: 200px;

							@media (max-width: 575px) {
								max-width: 100%;
								font-size: 18px;
							}
						}

						.text {
							color: #1313FF;
							line-height: 120%;
							font-size: 14px;
							font-family: "Inter-Regular", sans-serif;
							width: 100%;
							max-width: 177px;

							@media (max-width: 575px) {
								max-width: 246px;
								font-size: 13px;
							}
						}
					}

					&.one {
						background: #FFC6D5;
						

						@media (max-width: 575px) {
							left: 14px;
							right: 14px;
						}

						@media (min-width: 576px) {
							top: 104px;
						}
					}

					&.two {
						background: #FFB22D;
						left: 230px;
						top: 194px;
						z-index: 1;
						padding-bottom: 41px;

						@media (max-width: 575px) {
							left: 14px;
							right: 14px;
							top: 242px;
							z-index: 1;
							padding-bottom: 37px;
							padding-top: 21px;
							height: 269px;
						}

						.caption {
							gap: 15px;
						}
					}

					&.three {
						background: #fff;
						transform: rotate(-15deg);
						left: 412px;
						top: 20px;
						z-index: 2;
						padding-bottom: 41px;

						@media (max-width: 575px) {
							left: 14px;
							top: 496px;
							z-index: 2;
							padding-bottom: 37px;
							padding-right: 45px;
							padding-top: 21px;
							height: 290px;
						}

						.caption {
							gap: 13px;

							@media (max-width: 575px) {
								gap: 16px;
							}
						}
					}

					&.four {
						background: #F8D6B1;
						transform: rotate(15deg);
						left: 639px;
						top: 171px;
						z-index: 3;
						padding-bottom: 46px;

						@media (max-width: 575px) {
							left: -5px;
							top: 805px;
							z-index: 3;
							padding-bottom: 36px;
							padding-top: 20px;
							height: 240px;
						}

						.name,
						.text,
						.number {
							color: #fff;
						}

						.caption {
							gap: 33px;

							@media (max-width: 575px) {
								gap: 16px;
							}
						}
					}
				}
			}
		}
	}
}

section.four {
	overflow: hidden;

	.container {
		.inner {
			display: flex;
			flex-direction: column;

			.heading {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				max-width: 874px;
				width: 100%;
				z-index: 1;
				position: relative;
				pointer-events: none;

				span {
					color: #1313FF;
					line-height: 102%;
					font-size: 50px;
					font-family: "Inter-Bold", sans-serif;
					margin-left: 28px;
					width: 100%;
					max-width: 356px;
					margin-top: 17px;

					@media (max-width: 575px) {
						font-size: 34px;
						margin-left: 6px;
						margin-top: 26px;
					}
				}

				img {
					pointer-events: none;
					max-width: 310px;

					@media (max-width: 575px) {
						width: 218px;
						margin-top: 34px;
						margin-left: -20px;
					}

					@media (min-width: 576px) {
						animation: rotate 6s infinite alternate;

						@keyframes rotate {
							0% {
								transform: rotate(-1deg);
							}
							100% {
								transform: rotate(6deg);
							}
						}
					}
				}
			}

			.items {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin-top: -39px;

				@media (max-width: 575px) {
					margin-top: -12px;
				}

				.item {
					background: #FFFFFF;
					border-radius: 60px;
					
				
					.head {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 26px 36px;
						cursor: pointer;


						@media (max-width: 575px) {
							padding: 20px 25px 20px 28px;
						}
	
						@media (min-width: 768px) and (max-width: 1024px) {
							padding: 26px 30px 26px 36px;
						}

						span {
							color: #1313FF;
							line-height: 121%;
							font-size: 20px;
							font-family: "Inter-Medium", sans-serif;
							min-height: 38px;
							display: flex;
    					align-items: center;

							@media (max-width: 575px) {
								display: flex;
								align-items: center;
								font-size: 16px;
							}
						}

						button {
							border: 0;
							padding: 0;
							background: 0;
							transition: all 0.3s ease-in-out;
							line-height: 1;
							width: 26px;
							height: 26px;
							flex-shrink: 0;

							@media (max-width: 575px) {
								width: 18px;
								height: 18px;

								svg {
									width: 100%;
									height:100%;
								}
							}
						}
					}

					.text {
						color: #1313FF;
						line-height: 137%;
						font-size: 16px;
						font-family: "Inter-Regular", sans-serif;
						max-width: 690px;
						width: 100%;
						display: none;
						padding: 0 36px 45px;

						@media (max-width: 575px) {
							font-size: 13px;
							padding: 0px 25px 20px 28px;
						}

						@media (min-width: 768px) and (max-width: 1024px) {
							max-width: 630px;
							padding: 0px 30px 33px 36px;
						}
					}

					&.active {
						@media (max-width: 575px) {
							border-radius: 40px;
						}

						.head {
							button {
								transform: rotate(-45deg);
							}
						}
					}
				}
			}
		}
	}
}

section.five {
	margin-top: 108px;

	@media (max-width: 575px) {
		margin-top: 76px;
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		margin-top: 104px;
	}

	.container {
		.inner {
			display: flex;
			flex-direction: column;

			.heading {
				width: 100%;
				max-width: 626px;
				color: #000;
				line-height: 121%;
				font-size: 36px;
				font-family: "Inter-SemiBold", sans-serif;
				margin-left: auto;
				margin-right: auto;
				text-align: center;

				@media (max-width: 575px) {
					font-size: 24px;
					max-width: 255px;
				}

				i {
					font-family: 'Inter-MediumItalic', sans-serif;
				}

				span {
					color: #1313FF;

					b {
						font-family: "Inter-BlackItalic", sans-serif;
					}
				}
			}

			.block {
				gap: 20px;
				width: 100%;
				max-width: 744px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 62px;
				display: flex;
				justify-content: center;
				align-items: center;

				@media (max-width: 575px) {
					flex-wrap:wrap;
					margin-top: 56px;
					gap: 0;
				}

				@media (min-width: 768px) and (max-width: 1024px) {
					max-width: 710px;
				}

				a {
					position: relative;
					flex-shrink: 0;
					margin-top: -8px;

					&.disabled {
						opacity: 0.3;
						pointer-events: none;
					}

					span {
						position: absolute;
						color: #515151;
						font-size: 12px;
						left: 0;
						font-family: "Inter-MediumItalic", sans-serif;
						top: calc(100% + 13px);
						width: 176px;
						left: 50%;
						transform: translateX(-50%);

						@media (max-width: 575px) {
							font-size:10px;
							top: calc(100% + 7px);
							width: 143px;
						}

						u {
							text-decoration: none;
							border-bottom: 1px solid #515151;
						}
					}
				}

				.center {
					width: 100%;
					text-align: center;

					@media (max-width: 575px) {
						order: 1;
						margin-bottom: 34px;
					}

					img {
						max-width: 300px;

						@media (max-width: 575px) {
							max-width: 218px;
						}
					}
				}

				@media (max-width: 575px) {
					> *:nth-child(1) {
						order: 2;
						margin-top:0;

						img {
							max-width:131px;
						}
					}

					> *:nth-child(3) {
						order: 3;
						margin-top:0;
						margin-left:15px;

						img {
							max-width:140px;
						}
					}
				}
			}
		}
	}
}

section.information {
	margin-top: 72px;

	@media (max-width: 575px) {
		margin-top: 48px;
	}

	.container {
		.inner {
			display: flex;
			flex-direction: column;

			.heading {
				font-family: "Inter-SemiBold", sans-serif;
				font-size: 22px;

				@media (max-width: 575px) {
					font-size: 20px;
				}
			}

			.description {
				margin-top: 30px;
				font-size: 16px;
				line-height: 121%;

				@media (max-width: 575px) {
					font-size: 13px;
					margin-top: 24px;
				}

				ul {
					list-style: disc;
					margin-bottom: 20px;
					padding-left: 24px;

					@media (max-width: 575px) {
						padding-left: 19px;
					}

					li {
						& + li {
							margin-top: 20px;

							@media (max-width: 575px) {
								margin-top: 15px;
							}
						}
					}
				}

				h4 {
					font-family: "Inter-SemiBold", sans-serif;
					margin-bottom: 20px;
				}

				p {
					margin-bottom: 18px;

					@media (max-width: 575px) {
						margin-bottom: 17px;
					}

					&:last-child {
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}

footer {
	margin-top: 102px;

	@media (max-width: 575px) {
		margin-top: 84px;
	}

	.container {
		@media (max-width: 575px) {
			padding: 0
		}

		.inner {
			&__top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 10px;

				@media (max-width: 575px) {
					flex-direction: column;
					padding: 0 14px;
				}

				.left {
					height: 60px;
					width: 100%;
					max-width: 412px;
					background: #E3E3E3;
					border-radius: 30px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 27px;
					gap: 15px;

					span {
						font-size: 14px;
						white-space: nowrap;
						color: #7B7B7B;
						line-height: 130%;
						font-family: "Inter-Regular", sans-serif;
					}
				}

				.right {
					height: 60px;
					width: 100%;
					max-width: 412px;
					background: #E3E3E3;
					border-radius: 30px;
					padding: 0 21px;
					display: flex;
					align-items: center;
					justify-content: center;

					@media (max-width: 575px) {
						gap: 10px;
					}

					@media (min-width: 768px) and (max-width: 1024px) {
						padding: 0 31px;
					}

					ul {
						width: 100%;
						max-width: 332px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 30px;

						@media (max-width: 575px) {
							gap: 5px;
						}

						li {
							a {
								white-space: nowrap;
								font-size: 14px;
								color: #7B7B7B;
								line-height: 130%;
								font-family: "Inter-Regular", sans-serif;
							}
						}
					}
				}
			}

			&__middle {
				text-align: center;
				margin-top: 62px;
				color: #DDDDDD;
				font-size: 18px;
				font-family: "Inter-SemiBold", sans-serif;

				@media (max-width: 575px) {
					margin-top: 33px;
				}
			}

			&__bottom {
				margin-top: 36px;

				@media (max-width: 575px) {
					margin-top: 23px;
				}
			}
		}
	}
}