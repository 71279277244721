@font-face {
  font-family: "Inter-Regular";
  src: url(../fonts/Inter-Regular.otf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Inter-Medium";
  src: url(../fonts/Inter-Medium.otf) format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Inter-MediumItalic";
  src: url(../fonts/Inter-MediumItalic.otf) format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url(../fonts/Inter-SemiBold.otf) format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Inter-Bold";
  src: url(../fonts/Inter-Bold.otf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Inter-BlackItalic";
  src: url(../fonts/Inter-BlackItalic.otf) format("truetype");
  font-weight: 700;
  font-style: italic;
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body[data-aos-duration="50"] [data-aos], [data-aos][data-aos][data-aos-duration="50"] {
  transition-duration: 50ms;
}
body[data-aos-delay="50"] [data-aos], [data-aos][data-aos][data-aos-delay="50"] {
  transition-delay: 0;
}
body[data-aos-delay="50"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="50"].aos-animate {
  transition-delay: 50ms;
}
body[data-aos-duration="100"] [data-aos], [data-aos][data-aos][data-aos-duration="100"] {
  transition-duration: 100ms;
}
body[data-aos-delay="100"] [data-aos], [data-aos][data-aos][data-aos-delay="100"] {
  transition-delay: 0;
}
body[data-aos-delay="100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="100"].aos-animate {
  transition-delay: 100ms;
}
body[data-aos-duration="150"] [data-aos], [data-aos][data-aos][data-aos-duration="150"] {
  transition-duration: 150ms;
}
body[data-aos-delay="150"] [data-aos], [data-aos][data-aos][data-aos-delay="150"] {
  transition-delay: 0;
}
body[data-aos-delay="150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="150"].aos-animate {
  transition-delay: 150ms;
}
body[data-aos-duration="200"] [data-aos], [data-aos][data-aos][data-aos-duration="200"] {
  transition-duration: 200ms;
}
body[data-aos-delay="200"] [data-aos], [data-aos][data-aos][data-aos-delay="200"] {
  transition-delay: 0;
}
body[data-aos-delay="200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="200"].aos-animate {
  transition-delay: 200ms;
}
body[data-aos-duration="250"] [data-aos], [data-aos][data-aos][data-aos-duration="250"] {
  transition-duration: 250ms;
}
body[data-aos-delay="250"] [data-aos], [data-aos][data-aos][data-aos-delay="250"] {
  transition-delay: 0;
}
body[data-aos-delay="250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="250"].aos-animate {
  transition-delay: 250ms;
}
body[data-aos-duration="300"] [data-aos], [data-aos][data-aos][data-aos-duration="300"] {
  transition-duration: 300ms;
}
body[data-aos-delay="300"] [data-aos], [data-aos][data-aos][data-aos-delay="300"] {
  transition-delay: 0;
}
body[data-aos-delay="300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="300"].aos-animate {
  transition-delay: 300ms;
}
body[data-aos-duration="350"] [data-aos], [data-aos][data-aos][data-aos-duration="350"] {
  transition-duration: 350ms;
}
body[data-aos-delay="350"] [data-aos], [data-aos][data-aos][data-aos-delay="350"] {
  transition-delay: 0;
}
body[data-aos-delay="350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="350"].aos-animate {
  transition-delay: 350ms;
}
body[data-aos-duration="400"] [data-aos], [data-aos][data-aos][data-aos-duration="400"] {
  transition-duration: 400ms;
}
body[data-aos-delay="400"] [data-aos], [data-aos][data-aos][data-aos-delay="400"] {
  transition-delay: 0;
}
body[data-aos-delay="400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="400"].aos-animate {
  transition-delay: 400ms;
}
body[data-aos-duration="450"] [data-aos], [data-aos][data-aos][data-aos-duration="450"] {
  transition-duration: 450ms;
}
body[data-aos-delay="450"] [data-aos], [data-aos][data-aos][data-aos-delay="450"] {
  transition-delay: 0;
}
body[data-aos-delay="450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="450"].aos-animate {
  transition-delay: 450ms;
}
body[data-aos-duration="500"] [data-aos], [data-aos][data-aos][data-aos-duration="500"] {
  transition-duration: 500ms;
}
body[data-aos-delay="500"] [data-aos], [data-aos][data-aos][data-aos-delay="500"] {
  transition-delay: 0;
}
body[data-aos-delay="500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="500"].aos-animate {
  transition-delay: 500ms;
}
body[data-aos-duration="550"] [data-aos], [data-aos][data-aos][data-aos-duration="550"] {
  transition-duration: 550ms;
}
body[data-aos-delay="550"] [data-aos], [data-aos][data-aos][data-aos-delay="550"] {
  transition-delay: 0;
}
body[data-aos-delay="550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="550"].aos-animate {
  transition-delay: 550ms;
}
body[data-aos-duration="600"] [data-aos], [data-aos][data-aos][data-aos-duration="600"] {
  transition-duration: 600ms;
}
body[data-aos-delay="600"] [data-aos], [data-aos][data-aos][data-aos-delay="600"] {
  transition-delay: 0;
}
body[data-aos-delay="600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="600"].aos-animate {
  transition-delay: 600ms;
}
body[data-aos-duration="650"] [data-aos], [data-aos][data-aos][data-aos-duration="650"] {
  transition-duration: 650ms;
}
body[data-aos-delay="650"] [data-aos], [data-aos][data-aos][data-aos-delay="650"] {
  transition-delay: 0;
}
body[data-aos-delay="650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="650"].aos-animate {
  transition-delay: 650ms;
}
body[data-aos-duration="700"] [data-aos], [data-aos][data-aos][data-aos-duration="700"] {
  transition-duration: 700ms;
}
body[data-aos-delay="700"] [data-aos], [data-aos][data-aos][data-aos-delay="700"] {
  transition-delay: 0;
}
body[data-aos-delay="700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="700"].aos-animate {
  transition-delay: 700ms;
}
body[data-aos-duration="750"] [data-aos], [data-aos][data-aos][data-aos-duration="750"] {
  transition-duration: 750ms;
}
body[data-aos-delay="750"] [data-aos], [data-aos][data-aos][data-aos-delay="750"] {
  transition-delay: 0;
}
body[data-aos-delay="750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="750"].aos-animate {
  transition-delay: 750ms;
}
body[data-aos-duration="800"] [data-aos], [data-aos][data-aos][data-aos-duration="800"] {
  transition-duration: 800ms;
}
body[data-aos-delay="800"] [data-aos], [data-aos][data-aos][data-aos-delay="800"] {
  transition-delay: 0;
}
body[data-aos-delay="800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="800"].aos-animate {
  transition-delay: 800ms;
}
body[data-aos-duration="850"] [data-aos], [data-aos][data-aos][data-aos-duration="850"] {
  transition-duration: 850ms;
}
body[data-aos-delay="850"] [data-aos], [data-aos][data-aos][data-aos-delay="850"] {
  transition-delay: 0;
}
body[data-aos-delay="850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="850"].aos-animate {
  transition-delay: 850ms;
}
body[data-aos-duration="900"] [data-aos], [data-aos][data-aos][data-aos-duration="900"] {
  transition-duration: 900ms;
}
body[data-aos-delay="900"] [data-aos], [data-aos][data-aos][data-aos-delay="900"] {
  transition-delay: 0;
}
body[data-aos-delay="900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="900"].aos-animate {
  transition-delay: 900ms;
}
body[data-aos-duration="950"] [data-aos], [data-aos][data-aos][data-aos-duration="950"] {
  transition-duration: 950ms;
}
body[data-aos-delay="950"] [data-aos], [data-aos][data-aos][data-aos-delay="950"] {
  transition-delay: 0;
}
body[data-aos-delay="950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="950"].aos-animate {
  transition-delay: 950ms;
}
body[data-aos-duration="1000"] [data-aos], [data-aos][data-aos][data-aos-duration="1000"] {
  transition-duration: 1000ms;
}
body[data-aos-delay="1000"] [data-aos], [data-aos][data-aos][data-aos-delay="1000"] {
  transition-delay: 0;
}
body[data-aos-delay="1000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1000"].aos-animate {
  transition-delay: 1000ms;
}
body[data-aos-duration="1050"] [data-aos], [data-aos][data-aos][data-aos-duration="1050"] {
  transition-duration: 1050ms;
}
body[data-aos-delay="1050"] [data-aos], [data-aos][data-aos][data-aos-delay="1050"] {
  transition-delay: 0;
}
body[data-aos-delay="1050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1050"].aos-animate {
  transition-delay: 1050ms;
}
body[data-aos-duration="1100"] [data-aos], [data-aos][data-aos][data-aos-duration="1100"] {
  transition-duration: 1100ms;
}
body[data-aos-delay="1100"] [data-aos], [data-aos][data-aos][data-aos-delay="1100"] {
  transition-delay: 0;
}
body[data-aos-delay="1100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
  transition-delay: 1100ms;
}
body[data-aos-duration="1150"] [data-aos], [data-aos][data-aos][data-aos-duration="1150"] {
  transition-duration: 1150ms;
}
body[data-aos-delay="1150"] [data-aos], [data-aos][data-aos][data-aos-delay="1150"] {
  transition-delay: 0;
}
body[data-aos-delay="1150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1150"].aos-animate {
  transition-delay: 1150ms;
}
body[data-aos-duration="1200"] [data-aos], [data-aos][data-aos][data-aos-duration="1200"] {
  transition-duration: 1200ms;
}
body[data-aos-delay="1200"] [data-aos], [data-aos][data-aos][data-aos-delay="1200"] {
  transition-delay: 0;
}
body[data-aos-delay="1200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1200"].aos-animate {
  transition-delay: 1200ms;
}
body[data-aos-duration="1250"] [data-aos], [data-aos][data-aos][data-aos-duration="1250"] {
  transition-duration: 1250ms;
}
body[data-aos-delay="1250"] [data-aos], [data-aos][data-aos][data-aos-delay="1250"] {
  transition-delay: 0;
}
body[data-aos-delay="1250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1250"].aos-animate {
  transition-delay: 1250ms;
}
body[data-aos-duration="1300"] [data-aos], [data-aos][data-aos][data-aos-duration="1300"] {
  transition-duration: 1300ms;
}
body[data-aos-delay="1300"] [data-aos], [data-aos][data-aos][data-aos-delay="1300"] {
  transition-delay: 0;
}
body[data-aos-delay="1300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
  transition-delay: 1300ms;
}
body[data-aos-duration="1350"] [data-aos], [data-aos][data-aos][data-aos-duration="1350"] {
  transition-duration: 1350ms;
}
body[data-aos-delay="1350"] [data-aos], [data-aos][data-aos][data-aos-delay="1350"] {
  transition-delay: 0;
}
body[data-aos-delay="1350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1350"].aos-animate {
  transition-delay: 1350ms;
}
body[data-aos-duration="1400"] [data-aos], [data-aos][data-aos][data-aos-duration="1400"] {
  transition-duration: 1400ms;
}
body[data-aos-delay="1400"] [data-aos], [data-aos][data-aos][data-aos-delay="1400"] {
  transition-delay: 0;
}
body[data-aos-delay="1400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1400"].aos-animate {
  transition-delay: 1400ms;
}
body[data-aos-duration="1450"] [data-aos], [data-aos][data-aos][data-aos-duration="1450"] {
  transition-duration: 1450ms;
}
body[data-aos-delay="1450"] [data-aos], [data-aos][data-aos][data-aos-delay="1450"] {
  transition-delay: 0;
}
body[data-aos-delay="1450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1450"].aos-animate {
  transition-delay: 1450ms;
}
body[data-aos-duration="1500"] [data-aos], [data-aos][data-aos][data-aos-duration="1500"] {
  transition-duration: 1500ms;
}
body[data-aos-delay="1500"] [data-aos], [data-aos][data-aos][data-aos-delay="1500"] {
  transition-delay: 0;
}
body[data-aos-delay="1500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1500"].aos-animate {
  transition-delay: 1500ms;
}
body[data-aos-duration="1550"] [data-aos], [data-aos][data-aos][data-aos-duration="1550"] {
  transition-duration: 1550ms;
}
body[data-aos-delay="1550"] [data-aos], [data-aos][data-aos][data-aos-delay="1550"] {
  transition-delay: 0;
}
body[data-aos-delay="1550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1550"].aos-animate {
  transition-delay: 1550ms;
}
body[data-aos-duration="1600"] [data-aos], [data-aos][data-aos][data-aos-duration="1600"] {
  transition-duration: 1600ms;
}
body[data-aos-delay="1600"] [data-aos], [data-aos][data-aos][data-aos-delay="1600"] {
  transition-delay: 0;
}
body[data-aos-delay="1600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1600"].aos-animate {
  transition-delay: 1600ms;
}
body[data-aos-duration="1650"] [data-aos], [data-aos][data-aos][data-aos-duration="1650"] {
  transition-duration: 1650ms;
}
body[data-aos-delay="1650"] [data-aos], [data-aos][data-aos][data-aos-delay="1650"] {
  transition-delay: 0;
}
body[data-aos-delay="1650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1650"].aos-animate {
  transition-delay: 1650ms;
}
body[data-aos-duration="1700"] [data-aos], [data-aos][data-aos][data-aos-duration="1700"] {
  transition-duration: 1700ms;
}
body[data-aos-delay="1700"] [data-aos], [data-aos][data-aos][data-aos-delay="1700"] {
  transition-delay: 0;
}
body[data-aos-delay="1700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1700"].aos-animate {
  transition-delay: 1700ms;
}
body[data-aos-duration="1750"] [data-aos], [data-aos][data-aos][data-aos-duration="1750"] {
  transition-duration: 1750ms;
}
body[data-aos-delay="1750"] [data-aos], [data-aos][data-aos][data-aos-delay="1750"] {
  transition-delay: 0;
}
body[data-aos-delay="1750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1750"].aos-animate {
  transition-delay: 1750ms;
}
body[data-aos-duration="1800"] [data-aos], [data-aos][data-aos][data-aos-duration="1800"] {
  transition-duration: 1800ms;
}
body[data-aos-delay="1800"] [data-aos], [data-aos][data-aos][data-aos-delay="1800"] {
  transition-delay: 0;
}
body[data-aos-delay="1800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1800"].aos-animate {
  transition-delay: 1800ms;
}
body[data-aos-duration="1850"] [data-aos], [data-aos][data-aos][data-aos-duration="1850"] {
  transition-duration: 1850ms;
}
body[data-aos-delay="1850"] [data-aos], [data-aos][data-aos][data-aos-delay="1850"] {
  transition-delay: 0;
}
body[data-aos-delay="1850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1850"].aos-animate {
  transition-delay: 1850ms;
}
body[data-aos-duration="1900"] [data-aos], [data-aos][data-aos][data-aos-duration="1900"] {
  transition-duration: 1900ms;
}
body[data-aos-delay="1900"] [data-aos], [data-aos][data-aos][data-aos-delay="1900"] {
  transition-delay: 0;
}
body[data-aos-delay="1900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1900"].aos-animate {
  transition-delay: 1900ms;
}
body[data-aos-duration="1950"] [data-aos], [data-aos][data-aos][data-aos-duration="1950"] {
  transition-duration: 1950ms;
}
body[data-aos-delay="1950"] [data-aos], [data-aos][data-aos][data-aos-delay="1950"] {
  transition-delay: 0;
}
body[data-aos-delay="1950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="1950"].aos-animate {
  transition-delay: 1950ms;
}
body[data-aos-duration="2000"] [data-aos], [data-aos][data-aos][data-aos-duration="2000"] {
  transition-duration: 2000ms;
}
body[data-aos-delay="2000"] [data-aos], [data-aos][data-aos][data-aos-delay="2000"] {
  transition-delay: 0;
}
body[data-aos-delay="2000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2000"].aos-animate {
  transition-delay: 2000ms;
}
body[data-aos-duration="2050"] [data-aos], [data-aos][data-aos][data-aos-duration="2050"] {
  transition-duration: 2050ms;
}
body[data-aos-delay="2050"] [data-aos], [data-aos][data-aos][data-aos-delay="2050"] {
  transition-delay: 0;
}
body[data-aos-delay="2050"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2050"].aos-animate {
  transition-delay: 2050ms;
}
body[data-aos-duration="2100"] [data-aos], [data-aos][data-aos][data-aos-duration="2100"] {
  transition-duration: 2100ms;
}
body[data-aos-delay="2100"] [data-aos], [data-aos][data-aos][data-aos-delay="2100"] {
  transition-delay: 0;
}
body[data-aos-delay="2100"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2100"].aos-animate {
  transition-delay: 2100ms;
}
body[data-aos-duration="2150"] [data-aos], [data-aos][data-aos][data-aos-duration="2150"] {
  transition-duration: 2150ms;
}
body[data-aos-delay="2150"] [data-aos], [data-aos][data-aos][data-aos-delay="2150"] {
  transition-delay: 0;
}
body[data-aos-delay="2150"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2150"].aos-animate {
  transition-delay: 2150ms;
}
body[data-aos-duration="2200"] [data-aos], [data-aos][data-aos][data-aos-duration="2200"] {
  transition-duration: 2200ms;
}
body[data-aos-delay="2200"] [data-aos], [data-aos][data-aos][data-aos-delay="2200"] {
  transition-delay: 0;
}
body[data-aos-delay="2200"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2200"].aos-animate {
  transition-delay: 2200ms;
}
body[data-aos-duration="2250"] [data-aos], [data-aos][data-aos][data-aos-duration="2250"] {
  transition-duration: 2250ms;
}
body[data-aos-delay="2250"] [data-aos], [data-aos][data-aos][data-aos-delay="2250"] {
  transition-delay: 0;
}
body[data-aos-delay="2250"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2250"].aos-animate {
  transition-delay: 2250ms;
}
body[data-aos-duration="2300"] [data-aos], [data-aos][data-aos][data-aos-duration="2300"] {
  transition-duration: 2300ms;
}
body[data-aos-delay="2300"] [data-aos], [data-aos][data-aos][data-aos-delay="2300"] {
  transition-delay: 0;
}
body[data-aos-delay="2300"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2300"].aos-animate {
  transition-delay: 2300ms;
}
body[data-aos-duration="2350"] [data-aos], [data-aos][data-aos][data-aos-duration="2350"] {
  transition-duration: 2350ms;
}
body[data-aos-delay="2350"] [data-aos], [data-aos][data-aos][data-aos-delay="2350"] {
  transition-delay: 0;
}
body[data-aos-delay="2350"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2350"].aos-animate {
  transition-delay: 2350ms;
}
body[data-aos-duration="2400"] [data-aos], [data-aos][data-aos][data-aos-duration="2400"] {
  transition-duration: 2400ms;
}
body[data-aos-delay="2400"] [data-aos], [data-aos][data-aos][data-aos-delay="2400"] {
  transition-delay: 0;
}
body[data-aos-delay="2400"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2400"].aos-animate {
  transition-delay: 2400ms;
}
body[data-aos-duration="2450"] [data-aos], [data-aos][data-aos][data-aos-duration="2450"] {
  transition-duration: 2450ms;
}
body[data-aos-delay="2450"] [data-aos], [data-aos][data-aos][data-aos-delay="2450"] {
  transition-delay: 0;
}
body[data-aos-delay="2450"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2450"].aos-animate {
  transition-delay: 2450ms;
}
body[data-aos-duration="2500"] [data-aos], [data-aos][data-aos][data-aos-duration="2500"] {
  transition-duration: 2500ms;
}
body[data-aos-delay="2500"] [data-aos], [data-aos][data-aos][data-aos-delay="2500"] {
  transition-delay: 0;
}
body[data-aos-delay="2500"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2500"].aos-animate {
  transition-delay: 2500ms;
}
body[data-aos-duration="2550"] [data-aos], [data-aos][data-aos][data-aos-duration="2550"] {
  transition-duration: 2550ms;
}
body[data-aos-delay="2550"] [data-aos], [data-aos][data-aos][data-aos-delay="2550"] {
  transition-delay: 0;
}
body[data-aos-delay="2550"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2550"].aos-animate {
  transition-delay: 2550ms;
}
body[data-aos-duration="2600"] [data-aos], [data-aos][data-aos][data-aos-duration="2600"] {
  transition-duration: 2600ms;
}
body[data-aos-delay="2600"] [data-aos], [data-aos][data-aos][data-aos-delay="2600"] {
  transition-delay: 0;
}
body[data-aos-delay="2600"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2600"].aos-animate {
  transition-delay: 2600ms;
}
body[data-aos-duration="2650"] [data-aos], [data-aos][data-aos][data-aos-duration="2650"] {
  transition-duration: 2650ms;
}
body[data-aos-delay="2650"] [data-aos], [data-aos][data-aos][data-aos-delay="2650"] {
  transition-delay: 0;
}
body[data-aos-delay="2650"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2650"].aos-animate {
  transition-delay: 2650ms;
}
body[data-aos-duration="2700"] [data-aos], [data-aos][data-aos][data-aos-duration="2700"] {
  transition-duration: 2700ms;
}
body[data-aos-delay="2700"] [data-aos], [data-aos][data-aos][data-aos-delay="2700"] {
  transition-delay: 0;
}
body[data-aos-delay="2700"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2700"].aos-animate {
  transition-delay: 2700ms;
}
body[data-aos-duration="2750"] [data-aos], [data-aos][data-aos][data-aos-duration="2750"] {
  transition-duration: 2750ms;
}
body[data-aos-delay="2750"] [data-aos], [data-aos][data-aos][data-aos-delay="2750"] {
  transition-delay: 0;
}
body[data-aos-delay="2750"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2750"].aos-animate {
  transition-delay: 2750ms;
}
body[data-aos-duration="2800"] [data-aos], [data-aos][data-aos][data-aos-duration="2800"] {
  transition-duration: 2800ms;
}
body[data-aos-delay="2800"] [data-aos], [data-aos][data-aos][data-aos-delay="2800"] {
  transition-delay: 0;
}
body[data-aos-delay="2800"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2800"].aos-animate {
  transition-delay: 2800ms;
}
body[data-aos-duration="2850"] [data-aos], [data-aos][data-aos][data-aos-duration="2850"] {
  transition-duration: 2850ms;
}
body[data-aos-delay="2850"] [data-aos], [data-aos][data-aos][data-aos-delay="2850"] {
  transition-delay: 0;
}
body[data-aos-delay="2850"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2850"].aos-animate {
  transition-delay: 2850ms;
}
body[data-aos-duration="2900"] [data-aos], [data-aos][data-aos][data-aos-duration="2900"] {
  transition-duration: 2900ms;
}
body[data-aos-delay="2900"] [data-aos], [data-aos][data-aos][data-aos-delay="2900"] {
  transition-delay: 0;
}
body[data-aos-delay="2900"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2900"].aos-animate {
  transition-delay: 2900ms;
}
body[data-aos-duration="2950"] [data-aos], [data-aos][data-aos][data-aos-duration="2950"] {
  transition-duration: 2950ms;
}
body[data-aos-delay="2950"] [data-aos], [data-aos][data-aos][data-aos-delay="2950"] {
  transition-delay: 0;
}
body[data-aos-delay="2950"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="2950"].aos-animate {
  transition-delay: 2950ms;
}
body[data-aos-duration="3000"] [data-aos], [data-aos][data-aos][data-aos-duration="3000"] {
  transition-duration: 3000ms;
}
body[data-aos-delay="3000"] [data-aos], [data-aos][data-aos][data-aos-delay="3000"] {
  transition-delay: 0;
}
body[data-aos-delay="3000"] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay="3000"].aos-animate {
  transition-delay: 3000ms;
}

body[data-aos-easing=linear] [data-aos], [data-aos][data-aos][data-aos-easing=linear] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}
body[data-aos-easing=ease] [data-aos], [data-aos][data-aos][data-aos-easing=ease] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
body[data-aos-easing=ease-in] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}
body[data-aos-easing=ease-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-out] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
body[data-aos-easing=ease-in-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-back] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
body[data-aos-easing=ease-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-back] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
body[data-aos-easing=ease-in-out-back] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-back] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
body[data-aos-easing=ease-in-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-sine] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
body[data-aos-easing=ease-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-sine] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
body[data-aos-easing=ease-in-out-sine] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-sine] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
body[data-aos-easing=ease-in-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quad] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quad] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quad] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quad] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-cubic] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-cubic] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-cubic] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-cubic] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
body[data-aos-easing=ease-in-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-quart] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
body[data-aos-easing=ease-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-out-quart] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
body[data-aos-easing=ease-in-out-quart] [data-aos], [data-aos][data-aos][data-aos-easing=ease-in-out-quart] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform;
}
[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0);
}

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}
[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}

[data-aos=zoom-in] {
  transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

/**
 * Slide animations
 */
[data-aos^=slide][data-aos^=slide] {
  transition-property: transform;
}
[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translate3d(0, 0, 0);
}

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0);
}

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg);
}
[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg);
}
[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg);
}
[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg);
}
[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

html {
  scroll-behavior: smooth;
  background: #F8F8F8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  zoom: 100%;
}

body {
  font-family: "Inter-Regular", sans-serif;
  font-size: 100%;
  font-size: 16px;
  color: #000000;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

main {
  background: none;
  padding-top: 20px;
}
@media (max-width: 575px) {
  main {
    padding-top: 14px;
  }
}

header {
  background: none;
}

.container {
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}
@media (max-width: 575px) {
  .container {
    padding: 0 14px;
  }
}

header {
  top: 20px;
  position: sticky;
  z-index: 100;
}
@media (max-width: 575px) {
  header {
    top: 14px;
  }
}
header .container .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
header .container .inner .left {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 2px rgba(121, 121, 121, 0.1);
  border-radius: 10px;
  padding: 5px 32px 5px 5px;
  gap: 29px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
header .container .inner .left #logo {
  width: 40px;
  min-width: 40px;
  aspect-ratio: 1;
  position: relative;
  z-index: 0;
}
header .container .inner .left #logo img {
  width: 100%;
}
header .container .inner .left ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 29px;
}
@media (max-width: 575px) {
  header .container .inner .left ul {
    display: none;
  }
}
header .container .inner .left ul li a {
  font-family: "Inter-Medium", sans-serif;
  color: #000;
  font-size: 14px;
}
header .container .inner .left ul li a:hover {
  color: #1313FF;
}
header .container .inner .left .burger {
  width: 40px;
  height: 40px;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 6px;
  flex-direction: column;
  background: none;
  border: 0;
  padding: 0;
}
header .container .inner .left .burger span {
  background: #000;
  width: 22px;
  height: 2px;
  border-radius: 2px;
  display: block;
}
@media (max-width: 575px) {
  header .container .inner .left .burger {
    display: flex;
  }
}
header .container .inner .left:hover #logo img {
  transform: scaleX(-1);
}
@media (max-width: 575px) {
  header .container .inner .left {
    padding: 5px;
    gap: 5px;
  }
  header .container .inner .left.show {
    animation: width-anim-show 0.3s ease-in-out forwards;
  }
  header .container .inner .left.show ul.active {
    display: flex;
  }
  header .container .inner .left.show .burger {
    gap: 0;
  }
  header .container .inner .left.show .burger span:nth-child(1) {
    transform: rotate(45deg);
  }
  header .container .inner .left.show .burger span:nth-child(2) {
    display: none;
  }
  header .container .inner .left.show .burger span:nth-child(3) {
    transform: rotate(-45deg);
    margin-top: -2px;
  }
  @keyframes width-anim-show {
    0% {
      min-width: 94px;
    }
    100% {
      min-width: 100%;
    }
  }
  header .container .inner .left.hide {
    animation: width-anim-hide 0.3s ease-in-out forwards;
  }
  @keyframes width-anim-hide {
    0% {
      min-width: 100%;
    }
    100% {
      min-width: 94px;
    }
  }
}
header .container .inner .right {
  position: absolute;
  right: 0;
  top: 0;
}

section.first {
  margin-top: 53px;
}
section.first .container {
  padding: 0 0 0 20px;
}
@media (max-width: 575px) {
  section.first .container {
    padding: 0 0 0 14px;
  }
}
section.first .container .inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 394px;
}
@media (max-width: 575px) {
  section.first .container .inner {
    height: 255px;
  }
}
section.first .container .inner .text {
  font-family: "Inter-Bold", sans-serif;
  color: #000;
  font-size: 50px;
  width: 100%;
  line-height: 121%;
  max-width: 472px;
  min-width: 322px;
  margin-left: 30px;
  margin-top: 102px;
}
@media (max-width: 767px) {
  section.first .container .inner .text {
    font-size: 30px;
    max-width: 220px;
    min-width: 220px;
    margin-left: 0;
    margin-top: 13px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  section.first .container .inner .text {
    margin-left: 0;
    margin-top: 68px;
    font-size: 44px;
    max-width: 316px;
    min-width: 316px;
  }
}
section.first .container .inner .text span {
  color: #1313FF;
}
section.first .container .inner .quotes {
  position: relative;
  overflow-x: clip;
  width: 100%;
  max-width: 502px;
  height: 100%;
}
@media (max-width: 575px) {
  section.first .container .inner .quotes {
    margin-left: -92px;
    margin-top: 33px;
  }
}
section.first .container .inner .quotes .qoute {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px 20px 24px;
  gap: 8px;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 2px rgba(121, 121, 121, 0.1);
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}
section.first .container .inner .quotes .qoute:hover {
  background: #1313FF;
  color: #fff;
}
section.first .container .inner .quotes .qoute .type {
  font-size: 10px;
  font-family: "Inter-Regular", sans-serif;
  white-space: nowrap;
}
section.first .container .inner .quotes .qoute .head {
  font-size: 22px;
  font-family: "Inter-SemiBold", sans-serif;
  white-space: nowrap;
}
section.first .container .inner .quotes .qoute .tag {
  font-size: 14px;
  font-family: "Inter-Regular", sans-serif;
  white-space: nowrap;
}
section.first .container .inner .quotes .qoute.one {
  top: 11px;
  left: 265px;
  transform: rotate(-6.2deg);
}
@media (max-width: 575px) {
  section.first .container .inner .quotes .qoute.one {
    top: -29px;
    left: 125px;
  }
}
@media (min-width: 576px) {
  section.first .container .inner .quotes .qoute.one {
    animation: action1 5s infinite alternate;
  }
  @keyframes action1 {
    0% {
      transform: translateY(0) translateX(10px) rotate(-6.2deg);
    }
    100% {
      transform: translateY(-5px) translateX(-5px) rotate(-6.2deg);
    }
  }
}
section.first .container .inner .quotes .qoute.two {
  top: 110px;
  left: 37px;
  transform: rotate(4.8deg);
}
@media (min-width: 576px) {
  section.first .container .inner .quotes .qoute.two {
    animation: action2 4s infinite alternate;
  }
  @keyframes action2 {
    0% {
      transform: translateY(0) translateX(0) rotate(4.8deg);
    }
    100% {
      transform: translateY(-10px) translateX(-10px) rotate(9.8deg);
    }
  }
}
section.first .container .inner .quotes .qoute.three {
  top: 140px;
  left: 341px;
  transform: rotate(-26.2deg);
}
@media (max-width: 575px) {
  section.first .container .inner .quotes .qoute.three {
    display: none;
  }
}
@media (min-width: 576px) {
  section.first .container .inner .quotes .qoute.three {
    animation: action3 5s infinite alternate;
  }
  @keyframes action3 {
    0% {
      transform: translateY(0px) translateX(0px) rotate(-26.2deg);
    }
    100% {
      transform: translateY(30px) translateX(-5px) rotate(-26.2deg);
    }
  }
}
section.first .container .inner .quotes .qoute.four {
  top: 271px;
  left: 167px;
  transform: rotate(16.8deg);
}
@media (max-width: 575px) {
  section.first .container .inner .quotes .qoute.four {
    display: none;
  }
}
@media (min-width: 576px) {
  section.first .container .inner .quotes .qoute.four {
    animation: action4 4s infinite alternate;
  }
  @keyframes action4 {
    0% {
      transform: translateY(0) translateX(0) rotate(16.8deg);
    }
    100% {
      transform: translateY(10px) translateX(-10px) rotate(16.8deg);
    }
  }
}

section.second {
  margin-top: 117px;
}
@media (max-width: 575px) {
  section.second {
    margin-top: 53px;
  }
}
section.second .container .inner {
  gap: 10px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 575px) {
  section.second .container .inner {
    flex-direction: column;
    gap: 17px;
  }
}
section.second .container .inner .left {
  min-width: 310px;
  background: #FFFFFF;
  border-radius: 60px;
  padding: 61px 30px 37px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 575px) {
  section.second .container .inner .left {
    border-radius: 40px;
    min-width: unset;
    width: 100%;
    gap: 26px;
    padding: 36px 30px 33px;
    z-index: 2;
  }
}
section.second .container .inner .left .head {
  color: #1313FF;
  line-height: 121%;
  font-size: 50px;
  font-family: "Inter-Bold", sans-serif;
}
@media (max-width: 575px) {
  section.second .container .inner .left .head {
    font-size: 26px;
  }
}
section.second .container .inner .left a {
  background: #1313FF;
  width: 100%;
  height: 60px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Inter-Medium", sans-serif;
  color: #fff;
  font-size: 18px;
  padding: 0 20px;
}
@media (max-width: 575px) {
  section.second .container .inner .left a {
    height: 50px;
    font-size: 16px;
  }
}
section.second .container .inner .left a svg {
  transition: all 0.3s ease-in-out;
}
section.second .container .inner .left a:hover svg {
  transform: translateX(-5px);
}
section.second .container .inner .right {
  position: relative;
  width: 100%;
  background: #1313FF;
  border-radius: 60px;
  height: 568px;
  padding: 0 65px 63px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
@media (max-width: 575px) {
  section.second .container .inner .right {
    border-radius: 40px;
    z-index: 2;
    height: 578px;
    padding: 0 30px 48px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  section.second .container .inner .right {
    padding: 0 45px 63px;
  }
}
section.second .container .inner .right .image {
  position: absolute;
  top: -142px;
  right: 35px;
  max-width: 376px;
}
@media (max-width: 575px) {
  section.second .container .inner .right .image {
    top: -31px;
    right: 26px;
    width: 282px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  section.second .container .inner .right .image {
    right: 2px;
  }
}
section.second .container .inner .right .text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  gap: 35px;
  font-family: "Inter-Medium", sans-serif;
  color: #fff;
  width: 100%;
  max-width: 316px;
}
section.second .container .inner .right .text__top {
  font-size: 18px;
  line-height: 130%;
}
@media (max-width: 575px) {
  section.second .container .inner .right .text__top {
    font-size: 16px;
    max-width: 260px;
  }
}
section.second .container .inner .right .text__bottom {
  font-size: 13px;
  line-height: 130%;
}
@media (max-width: 575px) {
  section.second .container .inner .right .text__bottom {
    font-size: 12px;
    line-height: 137%;
  }
}

section.three {
  padding-top: 80px;
  padding-bottom: 49px;
}
@media (max-width: 575px) {
  section.three {
    padding-top: 71px;
  }
}
section.three .container {
  padding: 20px 0 0 0px;
  overflow: hidden;
}
@media (max-width: 575px) {
  section.three .container {
    padding: 0;
  }
}
section.three .container .inner {
  display: flex;
  flex-direction: column;
  position: relative;
}
section.three .container .inner .heading {
  color: #1313FF;
  line-height: 121%;
  font-size: 50px;
  font-family: "Inter-Bold", sans-serif;
  margin-left: 48px;
}
@media (max-width: 575px) {
  section.three .container .inner .heading {
    font-size: 34px;
    margin-left: 20px;
  }
}
section.three .container .inner .items {
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding-top: 83px;
  height: 630px;
  margin-top: -80px;
  overflow: hidden;
  padding-left: 20px;
}
@media (max-width: 575px) {
  section.three .container .inner .items {
    padding-top: 0px;
    margin-top: 19px;
    width: 100%;
    max-width: 100%;
    height: 1083px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  section.three .container .inner .items {
    overflow-x: auto;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
  }
  section.three .container .inner .items::-webkit-scrollbar {
    display: none;
  }
}
section.three .container .inner .items .item {
  width: 310px;
  height: 410px;
  border-radius: 60px;
  padding: 28px 37px 37px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  flex-shrink: 0;
}
@media (max-width: 575px) {
  section.three .container .inner .items .item {
    width: fit-content;
    height: 262px;
  }
}
section.three .container .inner .items .item .toy {
  display: block;
  position: absolute;
  right: 18px;
  top: -88px;
  animation: rotate4 3s infinite alternate;
  width: 140px;
  z-index: 4;
}
@keyframes rotate4 {
  0% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
section.three .container .inner .items .item .number {
  line-height: 1;
  font-size: 100px;
  font-family: "Inter-Bold", sans-serif;
  color: #1413FF;
}
@media (max-width: 575px) {
  section.three .container .inner .items .item .number {
    font-size: 80px;
  }
}
section.three .container .inner .items .item .caption {
  display: flex;
  flex-direction: column;
  gap: 21px;
}
@media (max-width: 575px) {
  section.three .container .inner .items .item .caption {
    gap: 14px;
  }
}
section.three .container .inner .items .item .caption .name {
  color: #1313FF;
  line-height: 121%;
  font-size: 22px;
  font-family: "Inter-Bold", sans-serif;
  width: 100%;
  max-width: 200px;
}
@media (max-width: 575px) {
  section.three .container .inner .items .item .caption .name {
    max-width: 100%;
    font-size: 18px;
  }
}
section.three .container .inner .items .item .caption .text {
  color: #1313FF;
  line-height: 120%;
  font-size: 14px;
  font-family: "Inter-Regular", sans-serif;
  width: 100%;
  max-width: 177px;
}
@media (max-width: 575px) {
  section.three .container .inner .items .item .caption .text {
    max-width: 246px;
    font-size: 13px;
  }
}
section.three .container .inner .items .item.one {
  background: #FFC6D5;
}
@media (max-width: 575px) {
  section.three .container .inner .items .item.one {
    left: 14px;
    right: 14px;
  }
}
@media (min-width: 576px) {
  section.three .container .inner .items .item.one {
    top: 104px;
  }
}
section.three .container .inner .items .item.two {
  background: #FFB22D;
  left: 230px;
  top: 194px;
  z-index: 1;
  padding-bottom: 41px;
}
@media (max-width: 575px) {
  section.three .container .inner .items .item.two {
    left: 14px;
    right: 14px;
    top: 242px;
    z-index: 1;
    padding-bottom: 37px;
    padding-top: 21px;
    height: 269px;
  }
}
section.three .container .inner .items .item.two .caption {
  gap: 15px;
}
section.three .container .inner .items .item.three {
  background: #fff;
  transform: rotate(-15deg);
  left: 412px;
  top: 20px;
  z-index: 2;
  padding-bottom: 41px;
}
@media (max-width: 575px) {
  section.three .container .inner .items .item.three {
    left: 14px;
    top: 496px;
    z-index: 2;
    padding-bottom: 37px;
    padding-right: 45px;
    padding-top: 21px;
    height: 290px;
  }
}
section.three .container .inner .items .item.three .caption {
  gap: 13px;
}
@media (max-width: 575px) {
  section.three .container .inner .items .item.three .caption {
    gap: 16px;
  }
}
section.three .container .inner .items .item.four {
  background: #F8D6B1;
  transform: rotate(15deg);
  left: 639px;
  top: 171px;
  z-index: 3;
  padding-bottom: 46px;
}
@media (max-width: 575px) {
  section.three .container .inner .items .item.four {
    left: -5px;
    top: 805px;
    z-index: 3;
    padding-bottom: 36px;
    padding-top: 20px;
    height: 240px;
  }
}
section.three .container .inner .items .item.four .name,
section.three .container .inner .items .item.four .text,
section.three .container .inner .items .item.four .number {
  color: #fff;
}
section.three .container .inner .items .item.four .caption {
  gap: 33px;
}
@media (max-width: 575px) {
  section.three .container .inner .items .item.four .caption {
    gap: 16px;
  }
}

section.four {
  overflow: hidden;
}
section.four .container .inner {
  display: flex;
  flex-direction: column;
}
section.four .container .inner .heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 874px;
  width: 100%;
  z-index: 1;
  position: relative;
  pointer-events: none;
}
section.four .container .inner .heading span {
  color: #1313FF;
  line-height: 102%;
  font-size: 50px;
  font-family: "Inter-Bold", sans-serif;
  margin-left: 28px;
  width: 100%;
  max-width: 356px;
  margin-top: 17px;
}
@media (max-width: 575px) {
  section.four .container .inner .heading span {
    font-size: 34px;
    margin-left: 6px;
    margin-top: 26px;
  }
}
section.four .container .inner .heading img {
  pointer-events: none;
  max-width: 310px;
}
@media (max-width: 575px) {
  section.four .container .inner .heading img {
    width: 218px;
    margin-top: 34px;
    margin-left: -20px;
  }
}
@media (min-width: 576px) {
  section.four .container .inner .heading img {
    animation: rotate 6s infinite alternate;
  }
  @keyframes rotate {
    0% {
      transform: rotate(-1deg);
    }
    100% {
      transform: rotate(6deg);
    }
  }
}
section.four .container .inner .items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: -39px;
}
@media (max-width: 575px) {
  section.four .container .inner .items {
    margin-top: -12px;
  }
}
section.four .container .inner .items .item {
  background: #FFFFFF;
  border-radius: 60px;
}
section.four .container .inner .items .item .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 36px;
  cursor: pointer;
}
@media (max-width: 575px) {
  section.four .container .inner .items .item .head {
    padding: 20px 25px 20px 28px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  section.four .container .inner .items .item .head {
    padding: 26px 30px 26px 36px;
  }
}
section.four .container .inner .items .item .head span {
  color: #1313FF;
  line-height: 121%;
  font-size: 20px;
  font-family: "Inter-Medium", sans-serif;
  min-height: 38px;
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  section.four .container .inner .items .item .head span {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
}
section.four .container .inner .items .item .head button {
  border: 0;
  padding: 0;
  background: 0;
  transition: all 0.3s ease-in-out;
  line-height: 1;
  width: 26px;
  height: 26px;
  flex-shrink: 0;
}
@media (max-width: 575px) {
  section.four .container .inner .items .item .head button {
    width: 18px;
    height: 18px;
  }
  section.four .container .inner .items .item .head button svg {
    width: 100%;
    height: 100%;
  }
}
section.four .container .inner .items .item .text {
  color: #1313FF;
  line-height: 137%;
  font-size: 16px;
  font-family: "Inter-Regular", sans-serif;
  max-width: 690px;
  width: 100%;
  display: none;
  padding: 0 36px 45px;
}
@media (max-width: 575px) {
  section.four .container .inner .items .item .text {
    font-size: 13px;
    padding: 0px 25px 20px 28px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  section.four .container .inner .items .item .text {
    max-width: 630px;
    padding: 0px 30px 33px 36px;
  }
}
@media (max-width: 575px) {
  section.four .container .inner .items .item.active {
    border-radius: 40px;
  }
}
section.four .container .inner .items .item.active .head button {
  transform: rotate(-45deg);
}

section.five {
  margin-top: 108px;
}
@media (max-width: 575px) {
  section.five {
    margin-top: 76px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  section.five {
    margin-top: 104px;
  }
}
section.five .container .inner {
  display: flex;
  flex-direction: column;
}
section.five .container .inner .heading {
  width: 100%;
  max-width: 626px;
  color: #000;
  line-height: 121%;
  font-size: 36px;
  font-family: "Inter-SemiBold", sans-serif;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media (max-width: 575px) {
  section.five .container .inner .heading {
    font-size: 24px;
    max-width: 255px;
  }
}
section.five .container .inner .heading i {
  font-family: "Inter-MediumItalic", sans-serif;
}
section.five .container .inner .heading span {
  color: #1313FF;
}
section.five .container .inner .heading span b {
  font-family: "Inter-BlackItalic", sans-serif;
}
section.five .container .inner .block {
  gap: 20px;
  width: 100%;
  max-width: 744px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 575px) {
  section.five .container .inner .block {
    flex-wrap: wrap;
    margin-top: 56px;
    gap: 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  section.five .container .inner .block {
    max-width: 710px;
  }
}
section.five .container .inner .block a {
  position: relative;
  flex-shrink: 0;
  margin-top: -8px;
}
section.five .container .inner .block a.disabled {
  opacity: 0.3;
  pointer-events: none;
}
section.five .container .inner .block a span {
  position: absolute;
  color: #515151;
  font-size: 12px;
  left: 0;
  font-family: "Inter-MediumItalic", sans-serif;
  top: calc(100% + 13px);
  width: 176px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 575px) {
  section.five .container .inner .block a span {
    font-size: 10px;
    top: calc(100% + 7px);
    width: 143px;
  }
}
section.five .container .inner .block a span u {
  text-decoration: none;
  border-bottom: 1px solid #515151;
}
section.five .container .inner .block .center {
  width: 100%;
  text-align: center;
}
@media (max-width: 575px) {
  section.five .container .inner .block .center {
    order: 1;
    margin-bottom: 34px;
  }
}
section.five .container .inner .block .center img {
  max-width: 300px;
}
@media (max-width: 575px) {
  section.five .container .inner .block .center img {
    max-width: 218px;
  }
}
@media (max-width: 575px) {
  section.five .container .inner .block > *:nth-child(1) {
    order: 2;
    margin-top: 0;
  }
  section.five .container .inner .block > *:nth-child(1) img {
    max-width: 131px;
  }
  section.five .container .inner .block > *:nth-child(3) {
    order: 3;
    margin-top: 0;
    margin-left: 15px;
  }
  section.five .container .inner .block > *:nth-child(3) img {
    max-width: 140px;
  }
}

section.information {
  margin-top: 72px;
}
@media (max-width: 575px) {
  section.information {
    margin-top: 48px;
  }
}
section.information .container .inner {
  display: flex;
  flex-direction: column;
}
section.information .container .inner .heading {
  font-family: "Inter-SemiBold", sans-serif;
  font-size: 22px;
}
@media (max-width: 575px) {
  section.information .container .inner .heading {
    font-size: 20px;
  }
}
section.information .container .inner .description {
  margin-top: 30px;
  font-size: 16px;
  line-height: 121%;
}
@media (max-width: 575px) {
  section.information .container .inner .description {
    font-size: 13px;
    margin-top: 24px;
  }
}
section.information .container .inner .description ul {
  list-style: disc;
  margin-bottom: 20px;
  padding-left: 24px;
}
@media (max-width: 575px) {
  section.information .container .inner .description ul {
    padding-left: 19px;
  }
}
section.information .container .inner .description ul li + li {
  margin-top: 20px;
}
@media (max-width: 575px) {
  section.information .container .inner .description ul li + li {
    margin-top: 15px;
  }
}
section.information .container .inner .description h4 {
  font-family: "Inter-SemiBold", sans-serif;
  margin-bottom: 20px;
}
section.information .container .inner .description p {
  margin-bottom: 18px;
}
@media (max-width: 575px) {
  section.information .container .inner .description p {
    margin-bottom: 17px;
  }
}
section.information .container .inner .description p:last-child {
  margin-bottom: 10px;
}

footer {
  margin-top: 102px;
}
@media (max-width: 575px) {
  footer {
    margin-top: 84px;
  }
}
@media (max-width: 575px) {
  footer .container {
    padding: 0;
  }
}
footer .container .inner__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
@media (max-width: 575px) {
  footer .container .inner__top {
    flex-direction: column;
    padding: 0 14px;
  }
}
footer .container .inner__top .left {
  height: 60px;
  width: 100%;
  max-width: 412px;
  background: #E3E3E3;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 27px;
  gap: 15px;
}
footer .container .inner__top .left span {
  font-size: 14px;
  white-space: nowrap;
  color: #7B7B7B;
  line-height: 130%;
  font-family: "Inter-Regular", sans-serif;
}
footer .container .inner__top .right {
  height: 60px;
  width: 100%;
  max-width: 412px;
  background: #E3E3E3;
  border-radius: 30px;
  padding: 0 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575px) {
  footer .container .inner__top .right {
    gap: 10px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  footer .container .inner__top .right {
    padding: 0 31px;
  }
}
footer .container .inner__top .right ul {
  width: 100%;
  max-width: 332px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 575px) {
  footer .container .inner__top .right ul {
    gap: 5px;
  }
}
footer .container .inner__top .right ul li a {
  white-space: nowrap;
  font-size: 14px;
  color: #7B7B7B;
  line-height: 130%;
  font-family: "Inter-Regular", sans-serif;
}
footer .container .inner__middle {
  text-align: center;
  margin-top: 62px;
  color: #DDDDDD;
  font-size: 18px;
  font-family: "Inter-SemiBold", sans-serif;
}
@media (max-width: 575px) {
  footer .container .inner__middle {
    margin-top: 33px;
  }
}
footer .container .inner__bottom {
  margin-top: 36px;
}
@media (max-width: 575px) {
  footer .container .inner__bottom {
    margin-top: 23px;
  }
}