@font-face{
	font-family:'Inter-Regular';
	src:url(../fonts/Inter-Regular.otf) format('truetype');
	font-weight:400;
	font-style:normal
}
@font-face{
	font-family:'Inter-Medium';
	src:url(../fonts/Inter-Medium.otf) format('truetype');
	font-weight:500;
	font-style:normal
}
@font-face{
	font-family:'Inter-MediumItalic';
	src:url(../fonts/Inter-MediumItalic.otf) format('truetype');
	font-weight:500;
	font-style:italic;
}
@font-face{
	font-family:'Inter-SemiBold';
	src:url(../fonts/Inter-SemiBold.otf) format('truetype');
	font-weight:600;
	font-style:normal
}
@font-face{
	font-family:'Inter-Bold';
	src:url(../fonts/Inter-Bold.otf) format('truetype');
	font-weight:700;
	font-style:normal
}
@font-face{
	font-family:'Inter-BlackItalic';
	src:url(../fonts/Inter-BlackItalic.otf) format('truetype');
	font-weight:700;
	font-style:italic;
}